import styled from "styled-components";

export const LogoWrapper = styled.div.attrs((props)=>({
    className: 'logo-wrapper'
}))`
    height: 80px;
    width: 200px;
`;

export const LogoImg = styled.img.attrs((props)=>({
    className: 'logo'
}))`
    height: 100%;
    object-fit: contain;
    width: 100%;
`;
import { ChangeEvent, useState } from "react";
import Swal from 'sweetalert2';
import { isValidEmail } from "utils/email";
import {addDoc, collection} from 'firebase/firestore';
import { db, firebaseCollections } from "utils/firebase";


const useContactForm = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [message, setMessage] = useState<string>('');

    const handleName = (e: ChangeEvent<HTMLInputElement>)=> {
        setName(e.target.value);
    }

    const handleLastName = (e: ChangeEvent<HTMLInputElement>)=> {
        setLastName(e.target.value);
    }

    const handleEmail = (e: ChangeEvent<HTMLInputElement>)=> {
        setEmail(e.target.value);
    }

    const handlePhone = (e: ChangeEvent<HTMLInputElement>)=> {
        setPhone(e.target.value);
    }

    const handleMessage = (e: ChangeEvent<HTMLTextAreaElement>)=> {
        setMessage(e.target.value);
    }

    const resetForm = ()=> {
        setName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setMessage('');
      }

    const validateForm = ()=> {
        if(!name){
            Swal.fire({
                text: 'First name is required!',
                icon: 'warning',
            });
            return;
        }
        if(!lastName){
            Swal.fire({
                text: 'Last name is required!',
                icon: 'warning',
            });
            return;
        }
        if(!email){
            Swal.fire({
                text: 'Email is required!',
                icon: 'warning',
            });
            return;
        }
        if(!isValidEmail(email)){
            Swal.fire({
                text: 'Email is invalid!',
                icon: 'warning',
            });
            return;
        }
        if(!phone){
            Swal.fire({
                text: 'Phone number is required!',
                icon: 'warning',
            });
            return;
        }
        if(!message){
            Swal.fire({
                text: 'Message is required!',
                icon: 'warning',
            });
            return;
        }
        handleSubmit();
    }

    const handleSubmit = async()=> {
        setLoading(true);
        const textEmail = `
            First name: ${name}
            Last name: ${lastName}
            Phone: ${phone}
            Email: ${email},
            Mensaje: ${message}
        `;
        const docRef = await addDoc(collection(db, firebaseCollections.contactForm), {
            to: 'info@asesoriasca.net',
            replyTo: `${name} ${lastName} <${email}>`,
            message: {
                subject: 'New email from Asesoriasca website',
                text: textEmail,
            } 
        });
        setLoading(false);
        if(docRef.id){
            resetForm();
            Swal.fire({
                text: 'Message sended!',
                icon: 'success',
            });
        }else{
            Swal.fire({
                text: 'Message can not sended try later!',
                icon: 'warning',
            });
        }
    }

  return {
    name,
    lastName,
    email,
    phone,
    message,
    loading,
    handleName,
    handleLastName,
    handleEmail,
    handlePhone,
    handleMessage,
    validateForm,
  }
}

export default useContactForm
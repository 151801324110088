import React from 'react';
import Logo from 'atoms/logo/Logo';
import { Container } from 'containers/Containers';
import { MainMenuColumn, MainMenuHolder, MainMenuWrapper, Menu, MenuItem, MainMenuContainer } from './MainMenu.components';
import { MainMenuOption, MainMenuOptions } from './OptionsMainMenu';
import gsap from 'gsap';
import {ScrollToPlugin} from 'gsap/ScrollToPlugin';
import useScroll from 'src/hooks/useScroll';

gsap.registerPlugin(ScrollToPlugin);

const MainMenu: React.FC = () => {

  const {HEIGHT_MENU} = useScroll();

  const handleClickMenuItem = (optionMenu: MainMenuOption)=> {
    switch (optionMenu) {
      case MainMenuOption.HOME:
        gsap.to(window, {duration: 0.5, scrollTo: 0});
        break;
      
      case MainMenuOption.SERVICES:
        const serviceSectionPosition = (document.querySelector('.services-wrapper') as HTMLDivElement).offsetTop;
        gsap.to(window, {duration: 0.5, scrollTo: (serviceSectionPosition - HEIGHT_MENU)});
        break;

      case MainMenuOption.CONTACT:
        const contactSectionPosition = (document.querySelector('.contact-wrapper') as HTMLDivElement).offsetTop;
        gsap.to(window, {duration: 0.5, scrollTo: (contactSectionPosition - HEIGHT_MENU)});
        break;
    
      default:
        break;
    }
  }

  return (
    <MainMenuContainer>

      <MainMenuWrapper>
        <Container>
          <MainMenuHolder>
            <MainMenuColumn>
              <Logo />
            </MainMenuColumn>
            <MainMenuColumn>  
              <Menu>
                {MainMenuOptions.map((optionMenu)=>(
                  <MenuItem 
                    id={optionMenu.id}
                    key={optionMenu.text}
                    onClick={()=>{handleClickMenuItem(optionMenu.text)}}
                  >
                      {optionMenu.text}
                    </MenuItem>
                ))}
              </Menu>
            </MainMenuColumn>
          </MainMenuHolder>
        </Container>
      </MainMenuWrapper>
    </MainMenuContainer>
  )
}

export default MainMenu
import React from 'react';
import { ButtonProps } from 'custom-types/atoms/Button';
import { ButtonComponent } from './Button.components';

const Button: React.FC<ButtonProps> = ({title, onClick}) => {
  return (
    <ButtonComponent 
      title={title}
      onClick={onClick}
    >
      {title}
    </ButtonComponent>
  )
}

export default Button
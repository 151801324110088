import styled from "styled-components";
import { colorBlack, Spacing } from "theme";

export const FooterWrapper = styled.footer.attrs((props)=>({
    className: 'footer-wrapper'
}))`
    background-color: ${colorBlack};
    padding: ${Spacing.small} 0;
`;

export const FooterHolder = styled.div.attrs((props)=>({
    className: 'footer-holder'
}))`
    text-align: center;
`;
import React, { useEffect, useRef } from 'react';
import { MapHolder } from './Map.components';

const MapComponent = ({center, zoom, children}: {center: google.maps.LatLngLiteral, zoom: number, children: React.ReactNode})=> {
    const ref = useRef<HTMLDivElement>(null);
    const [map, setMap] = React.useState<google.maps.Map>();
  
    useEffect(() => {
        if(ref.current && !map){
            setMap(new window.google.maps.Map(ref.current, {center,zoom}));
        }
    }, [ref, map, center, zoom]);
  
    return(
        <>
            <MapHolder ref={ref}>
            </MapHolder>
            {
                React.Children.map(children, (child) => {
                        if (React.isValidElement(child)) {
                        // set the map prop on the child component
                        return React.cloneElement(child, { map });
                    }
                })
            }
        </>
    )
  }

  export default MapComponent
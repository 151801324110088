import { CSSProperties } from "react";

export enum TextVariant {
    caption = '12px',
    body = '14px',
    bodyLarge = '18px',
};

export type TextProps = {
    color?: string;
    fontSize?: TextVariant;
    fontWeight?: string;
    fontStyle?: string;
    marginBottom?: string;
    style?: CSSProperties;
};
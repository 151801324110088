import { imgOfficeBg } from "images";
import styled from "styled-components";
import { Spacing } from "theme";

export const OfficeWrapper = styled.div.attrs((props)=>({
    className: 'office-wrapper'
}))``;

export const AddressWrapper = styled.address.attrs((props)=>({
    className: 'address-wrapper'
}))`
    padding: ${Spacing.large};
    background: url(${imgOfficeBg}) repeat;
    margin-bottom: ${Spacing.base};
`;

export const InfoAddressWrapper = styled.div.attrs((props)=>({
    className: 'info-address-wrapper'
}))`
    
    display: flex;

    svg {
        margin-right: ${Spacing.tiny};
    }
`;
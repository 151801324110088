import styled from "styled-components";
import { Breakpoints } from "theme";

export const OverlayHeaderWrapper = styled.section.attrs((props)=>({
    className: 'overlay-header-wrapper',
    id: 'overlay-header'
}))`
    height: 640px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`;

export const VideoWrapper = styled.div.attrs((pros)=>({
    className: 'video-wrapper'
}))`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
`;

export const OverlayVideo = styled.div.attrs((props)=>({
    className: 'overlay-video'
}))`
position: absolute;
    background: rgba(0,0,0,0.4);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
`;

export const OverlayContentWrapper = styled.div.attrs((props)=>({
    className: 'overlay-content-wrapper'
}))`
    width: 50%;
    z-index: 3;

    @media (max-width: ${Breakpoints.lg}){
        width: 80%;
    }
`;


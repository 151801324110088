import styled from 'styled-components';
import { Breakpoints, colorGreen, colorWhite, Spacing } from 'theme';

export const MainMenuContainer = styled.div.attrs((props)=>({
    className: 'main-menu-container',
}))`
    height: 128px;
    position: relative;
`;

export const MainMenuWrapper = styled.section.attrs((props)=>({
    className: 'main-menu-wrapper',
    id: 'menu'
}))`
    background-color: ${colorWhite};
    box-shadow: 0 10px 24px 0 rgb(0 0 0 / 25%);
    padding: ${Spacing.base};
    position: fixed;
    width: 100%;
    height: 128px;
    z-index: 999;
`;

export const MainMenuHolder = styled.div.attrs((props)=>({
    className: 'main-menu-holder'
}))`
    display: flex;
    align-items: center;

    @media (max-width: ${Breakpoints.lg}){
        flex-direction: column;
    }
`;

export const MainMenuColumn = styled.div.attrs((props)=>({
    className: 'main-menu-column'
}))`
    flex: 1;
`;

export const Menu = styled.ul.attrs((props)=>({
    className: 'main-menu'
}))`
    list-style: none;
`;

export const MenuItem = styled.li.attrs((props)=>({
    className: 'main-menu-item',
    id: props.id,
}))`
    display: inline-block;
    padding: 0 ${Spacing.small};
    cursor: pointer;
    transition: all 0.5s;
    font-weight: bold;

    :hover {
        color: ${colorGreen}
    }

    @media (max-width: ${Breakpoints.lg}){
        padding: 0 ${Spacing.tiny};
    }
`;
import styled from "styled-components";
import { colorBlack, colorGreen, colorLightGray, colorWhite, Spacing } from "theme";

export const ButtonComponent = styled.button.attrs(()=>({
    className: 'button-component',
    type: 'button',
}))`
    background: ${colorGreen};
    border: 3px solid transparent;
    color: ${colorWhite};
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.2px;
    transition: all 0.5s;
    text-transform: uppercase;
    padding: ${Spacing.small} ${Spacing.base};
    border-radius: ${Spacing.tiny};
    outline: none;

    :hover {
        background: ${colorWhite};
        border: 3px solid ${colorLightGray};
        color: ${colorBlack};
        cursor: pointer;
    }
`;
import React from 'react';
import MainMenu from 'organisms/mainMenu/MainMenu';
import Home from 'screens/home/Home';
import Services from 'organisms/services/Services';
import Contact from 'templates/contact/Contact';
import Footer from 'organisms/footer/Footer';

export const App: React.FC = ()=> {
  return (
    <>
      <MainMenu />
      <Home />
      <Services />
      <Contact />
      <Footer />
    </>
  );
}

export default App;

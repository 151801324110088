import React from 'react';
import Map from 'organisms/map/Map';
import { ContactOptionsHolder, ContactOptionsWrapper, ContactWrapper, MapColumn, MapsWrapper } from './Contact.components';
import { Container } from 'containers/Containers';
import OurDetails from 'organisms/ourDetails/OurDetails';
import ContactForm from 'organisms/contactForm/ContactForm';
import { Heading3 } from 'atoms/headings/Headings';

const Contact: React.FC = () => {
  return (
    <ContactWrapper>
      <Container>
        <MapsWrapper>
          <MapColumn>
            <Heading3>GUATEMALA <span>OFFICE</span></Heading3>
            <Map 
              position={{ lat: 14.234314, lng: -90.185590 }}
            />
          </MapColumn>
          <MapColumn>
            <Heading3>CANADA <span>OFFICE</span></Heading3>
            <Map 
              position={{ lat: 42.0541107, lng: -82.5996064 }}
            />
          </MapColumn>
        </MapsWrapper>
        <ContactOptionsWrapper>
            <ContactOptionsHolder>
              <OurDetails />
              <ContactForm />
            </ContactOptionsHolder>
        </ContactOptionsWrapper>
        </Container>
    </ContactWrapper>
  )
}

export default Contact
import React, { ReactElement } from 'react';
import { MapWrapper } from './Map.components';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { Text } from 'atoms/text/Text';
import Marker from './Marker';
import MapComponent from './MapComponent';

type MapProps = {
  position: google.maps.LatLngLiteral;
};

const Map: React.FC<MapProps> = ({position}) => {
    const zoom = 15;

    const render = (status: Status): ReactElement => {
        return <Text>{status} ...</Text>;
    };

  return (
    <MapWrapper>
      <Wrapper apiKey={"AIzaSyCBGnPdaptHGo8Ilz3_vsSgPzAhgjMRVEw"} render={render}>
          <MapComponent  center={position}  zoom={zoom}>
            <Marker position={position} />
          </MapComponent>
      </Wrapper>
    </MapWrapper>
  )
}

export default Map
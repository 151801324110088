import styled from "styled-components";
import { Breakpoints, Spacing } from "theme";

export const ContactWrapper = styled.section.attrs((props)=>({
    className: 'contact-wrapper',
    id: 'contact'
}))`
    margin-bottom: ${Spacing.xxxLarge};
    padding-top: ${Spacing.base};
`;

export const MapsWrapper = styled.div.attrs((props)=>({
    className: 'maps-wrapper'
}))`
    display: flex;
    padding-bottom: 98px;
    gap: ${Spacing.base};

    @media (max-width: ${Breakpoints.lg}){
        flex-direction: column;
    }
`;

export const MapColumn = styled.div.attrs((props)=>({
    className: 'maps-column'
}))`
    flex: 1;
`;

export const ContactOptionsWrapper = styled.div.attrs((props)=>({
    className: 'contact-options-wrapper'
}))``;

export const ContactOptionsHolder = styled.div.attrs((props)=>({
    className: 'contact-options-holder'
}))`
    display: flex;
    gap: ${Spacing.base};

    @media (max-width: ${Breakpoints.lg}){
        flex-direction: column;
    }
`;
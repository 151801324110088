export enum Spacing {
    tiny = '8px',
    small = '16px',
    base = '24px',
    medium = '32px',
    large = '40px',
    xLarge = '48px',
    xxLarge = '56px',
    xxxLarge = '64px',
    marginSection = '98px',
};

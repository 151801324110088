import styled from "styled-components";
import { Breakpoints, colorGreen, Spacing } from "theme";

export const ImageServiceWrapper = styled.div.attrs((props)=>({
    className: 'image-service-wrapper'
}))`
    width: 300px;
    height: 320px;
    margin-bottom: ${Spacing.base};

    @media (max-width: ${Breakpoints.lg}){
        width: 200px;
    }
`;

export const ImgService = styled.img.attrs((props)=>({
    className: 'img-service'
}))`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;
import styled from "styled-components";
import { TextProps } from "custom-types/atoms/Text";
import { Spacing } from 'theme';

export const Heading1 = styled.h1.attrs((props)=>({
    className: 'heading-1',
}))<TextProps>`
    color: ${props => props.color || 'black'};
    font-style: ${props => props.fontStyle || 'normal'};
    font-size: ${props => props.fontSize || '48px'};
    font-weight: ${props => props.fontWeight || 'bold'};
    margin-bottom: ${props => props.marginBottom || Spacing.small};
`;

export const Text = styled.p.attrs((props)=>({
    className: 'text',
}))<TextProps>`
    color: ${props => props.color || 'black'};
    font-style: ${props => props.fontStyle || 'normal'};
    font-size: ${props => props.fontSize || '16px'};
    font-weight: ${props => props.fontWeight || 'normal'};
    margin-bottom: ${props => props.marginBottom || Spacing.small};
`;

export const TextHed = styled.p.attrs((props)=>({
    className: 'text-hed',
}))`
    color: #222222;
    font-style: normal;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: ${Spacing.tiny};
`;
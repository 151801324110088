import React from 'react';
import { OverLayHeaderProps } from 'custom-types/molecules/OverlayHeader';
import { OverlayContentWrapper, OverlayHeaderWrapper, OverlayVideo, VideoWrapper } from './OverlayHeader.components';
import { videoHeaderMp4 } from 'assets';
import Video from 'molecules/video/Video';

const OverlayHeader: React.FC<OverLayHeaderProps> = ({backgroundImage, content}) => {
  return (
    <OverlayHeaderWrapper>
      <VideoWrapper>
        <Video
          src={videoHeaderMp4}
        />
      </VideoWrapper>
      <OverlayVideo />
      <OverlayContentWrapper>
        {content}
      </OverlayContentWrapper>
    </OverlayHeaderWrapper>
  )
}

export default OverlayHeader
import React from 'react';
import { imgLogo } from 'images';
import { LogoImg, LogoWrapper } from './Logo.components';

const Logo = () => {
  return (
    <LogoWrapper>
      <LogoImg src={imgLogo} />
    </LogoWrapper>
  )
}

export default Logo
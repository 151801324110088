import React from 'react';
import { InputTextProps } from 'custom-types/atoms/InputText'
import { Input } from './InputText.components'

const InputText: React.FC<InputTextProps> = ({placeholder, value, onChange}) => {
  return (
    <Input placeholder={placeholder} value={value} onChange={onChange} />
  )
}

export default InputText
import React from 'react';
import { Text, TextHed } from 'atoms/text/Text';
import { OfficeProps } from 'custom-types/molecules/Office';
import { AddressWrapper, OfficeWrapper, InfoAddressWrapper } from './Office.components';
import { EnvelopeSimple, MapPin, Phone } from 'phosphor-react';
import { colorGreen } from 'theme';

const Office: React.FC<OfficeProps> = ({title, address, phone, email}) => {
  return (
    <OfficeWrapper>
        <AddressWrapper>
            <TextHed>{title}</TextHed>
            <InfoAddressWrapper>
                <MapPin color={colorGreen} size={16} />
                <Text 
                    marginBottom='8px'
                >
                    {address}
                </Text>
            </InfoAddressWrapper>
            <InfoAddressWrapper>
                <Phone color={colorGreen} size={16} />
                <Text 
                    marginBottom='8px'
                >
                    {phone}
                </Text>
            </InfoAddressWrapper>
            <InfoAddressWrapper>
                <EnvelopeSimple color={colorGreen} size={16} />
                <Text 
                    marginBottom='0px'
                >
                    {email}
                </Text>
            </InfoAddressWrapper>
        </AddressWrapper>
    </OfficeWrapper>
  )
}

export default Office
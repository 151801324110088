import styled from "styled-components";

export const MapWrapper = styled.div.attrs((props)=>({
    className: 'map-wrapper'
}))``;

export const MapHolder = styled.div.attrs((props)=>({
    className: 'map-holder'
}))`
    height: 364px;
`;
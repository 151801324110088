import React from 'react';
import { ContactDataHolder, ContactFormHolder, ContactFormWrapper } from './ContactForm.components';
import { Heading3 } from '../../atoms/headings/Headings';
import InputText from 'atoms/inputText/InputText';
import TextArea from 'atoms/textArea/TextArea';
import Button from 'atoms/button/Button';
import useContactForm from 'hooks/useContactForm';
import Loader from 'atoms/loader/Loader';

const ContactForm: React.FC = () => {

  const {
    loading,
    name,
    lastName,
    email,
    phone, 
    message,
    handleName,
    handleLastName,
    handleEmail,
    handlePhone,
    handleMessage,
    validateForm
  } = useContactForm();

  return (
    <ContactFormWrapper>
        <Heading3>SEND <span>MESSAGE</span></Heading3>
        <ContactFormHolder>
          <ContactDataHolder>
            <InputText 
              placeholder='First name'
              value={name}
              onChange={handleName}
            />
            <InputText 
              placeholder='Last name'
              value={lastName}
              onChange={handleLastName}
            />
          </ContactDataHolder>
          <ContactDataHolder>
            <InputText 
              placeholder='Email address'
              value={email}
              onChange={handleEmail}
            />
            <InputText 
              placeholder='Phone no.'
              value={phone}
              onChange={handlePhone}
            />
          </ContactDataHolder>
          <TextArea 
            placeholder='Write your message...'
            value={message}
            onChange={handleMessage}
          />
          <Button 
            title='Send' 
            onClick={validateForm}
          />
        </ContactFormHolder>
        {
          loading &&
          <Loader />
        }
    </ContactFormWrapper>
  )
}

export default ContactForm
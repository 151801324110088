import { Heading3 } from 'atoms/headings/Headings';
import Office from 'molecules/office/Office';
import React from 'react';
import { OurDetailsWrapper } from './OurDetails.components';

const OurDetails: React.FC = () => {
  return (
    <OurDetailsWrapper>
        <Heading3>Our <span>Details</span></Heading3>
        <Office 
          title='GUATEMALA OFFICE:'
          address='2da calle, 0-14 Barrio El Bado, Oratorio Santa Rosa'
          phone='+502 4185 4642'
          email='info@asesoriasca.net'
        />
        <Office 
          title='CANADA OFFICE:'
          address='38 Erie st N Leamington, ON N8H 2Z3'
          phone='+519 3980418'
          email='info@asesoriasca.net'
        />
    </OurDetailsWrapper>
  )
}

export default OurDetails
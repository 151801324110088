import React from 'react';
import { TextAreaProps } from 'custom-types/atoms/TextArea'
import { TextAreaInput } from './TextArea.components'

const TextArea: React.FC<TextAreaProps> = ({placeholder, value, onChange}) => {
  return (
    <TextAreaInput placeholder={placeholder} value={value} onChange={onChange} />
  )
}

export default TextArea
import React from 'react';
import { Text } from 'atoms/text/Text';
import { colorWhite } from 'theme';
import { FooterHolder, FooterWrapper } from './Footer.components';
import { Container } from 'containers/Containers';
import { getCurrentYear } from 'utils/dates';

const Footer: React.FC = () => {
  return (
    <FooterWrapper>
        <Container>
            <FooterHolder>
                <Text
                    color={colorWhite}
                    fontWeight='bold'
                >
                    {`Copyrights ${getCurrentYear()} ASESORIAS CA • All Rights Reserved.`}
                </Text>
            </FooterHolder>
        </Container>
    </FooterWrapper>
  )
}

export default Footer
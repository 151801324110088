import styled from "styled-components";
import { Spacing } from "theme";

export const ContactFormWrapper = styled.div.attrs(()=>({
    className: 'contact-form-wrapper'
}))`
    flex: 1;
`;


export const ContactFormHolder = styled.div.attrs(()=>({
    className: 'contact-form-holder'
}))`
    .text-area {
        height: 200px;
        width: 100%;
    }
`;

export const ContactDataHolder = styled.div.attrs(()=>({
    className: 'contact-data-holder'
}))`
    display: flex;
    gap: ${Spacing.small};
    flex-wrap: wrap;

    input {
        flex: 1;
    }
`;

export enum MainMenuOption {
    HOME = "HOME",
    SERVICES = "SERVICES",
    CONTACT = "CONTACT"
};

export enum MainMenuIds {
    NAV_HOME = "nav-home",
    NAV_SERVICES = "nav-services",
    NAV_CONTACT = "nav-contact"
};

export type MainMenuOptionsProps = {
    text: MainMenuOption,
    id: MainMenuIds;
};

export const MainMenuOptions: MainMenuOptionsProps[] = [
    {
        id: MainMenuIds.NAV_HOME,
        text: MainMenuOption.HOME,
    },
    {
        id: MainMenuIds.NAV_SERVICES,
        text: MainMenuOption.SERVICES,
    },
    {
        id: MainMenuIds.NAV_CONTACT,
        text: MainMenuOption.CONTACT,
    }
];
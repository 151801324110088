import logo from './img/logo_asesorias_ca.png';
import service1 from './img/img_service_1.jpeg';
import service2 from './img/img_service_2.jpeg';
import service3 from './img/img_service_3.jpeg';
import service4 from './img/img_service_4.jpeg';
import officeBg from './img/office_bg.png';
import line from './img/line.png';

export const imgLogo = logo;
export const imgService1 = service1;
export const imgService2 = service2;
export const imgService3 = service3;
export const imgService4 = service4;
export const imgOfficeBg = officeBg;
export const imgLine = line;
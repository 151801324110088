import styled from "styled-components";
import { Spacing } from "theme";

export const Input = styled.input.attrs((props)=>({
    className: 'input-text'
}))`
    background: none;
    border: 1px solid #cccccc;
    border-radius: 2px;
    box-shadow: none !important;
    height: 43px;
    font-size: 14px;;
    margin-bottom: 20px;
    outline: none;
    padding-left: ${Spacing.tiny};
`;
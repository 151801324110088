import React from 'react'
import { ImageServiceProps } from 'custom-types/atoms/ImageService'
import { ImageServiceWrapper, ImgService } from './ImageService.components'

const ImageService: React.FC<ImageServiceProps> = ({image}) => {
  return (
    <ImageServiceWrapper>
        <ImgService src={image} />
    </ImageServiceWrapper>
  )
}

export default ImageService
import styled from "styled-components";
import { imgLine } from "images";
import { colorBlack, colorGreen, Spacing } from "theme";

export const Heading2 = styled.h2`
    font-size: 36px;
    color: ${props => props.color || colorBlack};
    margin-bottom: ${Spacing.small};
`;

export const Heading3 = styled.h3`
    background: url(${imgLine}) no-repeat left bottom;
    color: ${props => props.color || colorBlack};
    font-size: 20px;
    padding-bottom: 10px;
    text-transform: uppercase;
    margin-bottom: ${Spacing.medium};

    span {
        color: ${colorGreen};
    }
`;
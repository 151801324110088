import React from 'react';
import { Heading2 } from 'atoms/headings/Headings';
import { Container } from 'containers/Containers';
import { colorGreen } from 'theme';
import { ServicesColumn, ServicesContentWrapper, ServicesImagesWrapper, ServicesTitleWrapper, ServicesWrapper } from './Services.components';
import { Text } from 'atoms/text/Text';
import ImageService from 'atoms/imageService/ImageService';
import { imgService1, imgService2, imgService3, imgService4 } from 'images';

const Services: React.FC = () => {

  return (
    <ServicesWrapper>
      <Container>
        <ServicesTitleWrapper>
          <Heading2
            color={colorGreen}
          >
            Services
          </Heading2>  
          <ServicesContentWrapper>
            <ServicesColumn>
              <ServicesImagesWrapper>  
                <ImageService image={imgService1} />
                <ImageService image={imgService2} />
              </ServicesImagesWrapper>
            </ServicesColumn>
            <ServicesColumn>
              <Text>
                Utilizing our headquarters in Ontario and our sister company in 
                Guatemala, we specialize in recruiting Guatemalans for your labour demands. 
                We fully assist with acquiring work visas/permits using your positive LMIA	
                or we can recruit and suggest workers for your LMIA names. Through	
                diligent background work with our team in Guatemala we are able to select 
                the labourers best suited for your company. 			
              </Text>
              <Text>
                Asesorias CA staff diligently follows the case status of each worker, providing 
                updates of the process, arranging online meetings with the workers, allowing 
                the employer to meet the worker before arriving to site. Our 		
                mission is to coordinate the recruitment, selection, and transportation	
                of the TFW's in a diligent efficient manner; always meeting the legal requirements as
                we seek to process and fulfill the labour supply and demand for the Canadian workforce.
              </Text>
            </ServicesColumn>
            <ServicesColumn>
              <ServicesImagesWrapper>
                <ImageService image={imgService3} />
                <ImageService image={imgService4} />
              </ServicesImagesWrapper>
            </ServicesColumn>
          </ServicesContentWrapper>
        </ServicesTitleWrapper>
      </Container>
    </ServicesWrapper>
  )
}

export default Services
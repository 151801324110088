import React from 'react';
import { Heading1, Text } from 'atoms/text/Text';
import { TextVariant } from 'custom-types/atoms/Text';
import OverlayHeader from 'molecules/overlayHeader/OverlayHeader';
import { colorWhite } from 'theme';


const Home: React.FC = () => {
  return (
    <>
      <OverlayHeader
        backgroundImage='https://placeimg.com/1920/640/nature'
        content={
          <>
            <Heading1
              color={colorWhite}
            >
              Welcome to Asesorias CA
            </Heading1>
            <Text
              color={colorWhite}
              fontSize={TextVariant.bodyLarge}
              fontWeight='bold'
              fontStyle='italic'
            >
              Your service provider for 
              recruiting, selecting, and hiring temporary foreign workers. 
              We bridge the gap between your company's labour needs
              and the legal process of receiving qualified persons to conduct those tasks. 
            </Text>
            <Text
              color={colorWhite}
              fontSize={TextVariant.bodyLarge}
              fontWeight='bold'
              fontStyle='italic'
            >
              We are the solution between your company's labour demands
              and the shortage of Canadians to fulfill the gap in the workforce
              through international recruitment. 		
            </Text>
          </>
        }
      />
    </>
  )
}

export default Home
import styled from "styled-components";
import { Breakpoints, Spacing } from "theme";

export const ServicesWrapper = styled.section.attrs((props)=>({
    className: 'services-wrapper',
    id: 'services'
}))`
    padding: ${Spacing.marginSection} 0;
`;

export const ServicesTitleWrapper = styled.div.attrs((props)=>({
    className: 'services-title-wrapper'
}))`
    text-align: center;
`;

export const ServicesContentWrapper = styled.div.attrs((props)=>({
    className: 'services-content-wrapper'
}))`
    display: flex;
    align-items: center;
    gap: ${Spacing.base};

    @media (max-width: ${Breakpoints.lg}){
        flex-direction: column;
    }
`;

export const ServicesColumn = styled.div.attrs((props)=>({
    className: 'services-column'
}))`
`;

export const ServicesImagesWrapper = styled.div.attrs((props)=>({
    className: 'services-images-wrapper'
}))`
    @media (max-width: ${Breakpoints.lg}){
        display: flex;
        gap: ${Spacing.medium};
        margin-bottom: ${Spacing.base};
    }
`;
import { useEffect } from "react";
import { colorBlack, colorGreen } from "theme";
import { MainMenuIds } from '../design/organisms/mainMenu/OptionsMainMenu';


const useScroll = () => {

    const HEIGHT_MENU = 128;

  const handleScroll = ()=> {
    const scroll = document.documentElement.scrollTop;
    const navHome = document.getElementById(MainMenuIds.NAV_HOME);
    const navServices = document.getElementById(MainMenuIds.NAV_SERVICES);
    const navContact = document.getElementById(MainMenuIds.NAV_CONTACT);
    const serviceSection = document.querySelector('.services-wrapper') as HTMLDivElement;
    const contactSection = document.querySelector('.contact-wrapper') as HTMLDivElement;
    if(serviceSection && (scroll + HEIGHT_MENU) < serviceSection.offsetTop){
        if(navHome){
            navHome.style.color = colorGreen;
        }
        if(navServices){
            navServices.style.color = colorBlack;
        }
    }
    if(serviceSection && (scroll + HEIGHT_MENU) >= serviceSection.offsetTop){
        if(navHome){
            navHome.style.color = colorBlack;
        }
        if(navServices){
            navServices.style.color = colorGreen;
        }
    }
    if(contactSection && (scroll + HEIGHT_MENU) < contactSection.offsetTop){
        if(navContact){
            navContact.style.color = colorBlack;
        }
    }
    if(contactSection && (scroll + HEIGHT_MENU) >= contactSection.offsetTop){
        if(navServices){
            navServices.style.color = colorBlack;
        }
        if(navContact){
            navContact.style.color = colorGreen;
        }
    }
  };

  useEffect(()=>{
    const navHome = document.getElementById(MainMenuIds.NAV_HOME);
    if(navHome){
        navHome.style.color = colorGreen;
    }
  },[]);

  useEffect(()=>{
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  },[]);

  return{
    HEIGHT_MENU,
  }
}

export default useScroll
import React, {useRef, useEffect} from "react"
import { SilentVideo } from "./Video.Components";

type VideoProps = {
    src: string;
};

const Video: React.FC<VideoProps> = ({src}) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    
    useEffect(() => {
        if(videoRef.current){
            videoRef.current.defaultMuted = true;
        }
    })

    return (
        <SilentVideo
            ref={videoRef}
            loop
            autoPlay
            muted
            playsInline
        >
            <source src={src} type="video/mp4"/>
        </SilentVideo>
    );
}

export default Video
export const config = {
    firebaseConfig: {
        apiKey: "AIzaSyBcDuAUiowSBP8Y6ijygmTZH5uoLrtHvQI",
        authDomain: "asesoriascawebnet.firebaseapp.com",
        projectId: "asesoriascawebnet",
        storageBucket: "asesoriascawebnet.appspot.com",
        messagingSenderId: "357085746706",
        appId: "1:357085746706:web:06952b0687d01f682b6acb",
        measurementId: "G-M5CVZJ7GCK"
    }
}
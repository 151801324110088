import styled from "styled-components";
import { Spacing } from "theme";

export const TextAreaInput = styled.textarea.attrs(()=>({
    className: 'text-area'
}))`
    background: none;
    border: 1px solid #cccccc;
    border-radius: 2px;
    box-shadow: none;
    font-size: 14px;
    margin-bottom: 20px;
    outline: none;
    padding-left: ${Spacing.tiny};
    padding-top: ${Spacing.tiny};
    resize: none;
`;